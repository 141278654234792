*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

html {
  font-size: 62.5%;
}

body {
  background-image: url('./images/bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  box-sizing: border-box;
  font-family: 'Afacad', sans-serif;
  font-size: 2rem;
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row {
  display: flex;
}

.board {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 1fr);
  gap: 10px;
}

.promotion-grid {
  width: calc(7rem + 20px);
  height: calc(28rem + 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  top: -13px;
  bottom: -3px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, .35);
  border-radius: 5px;
  cursor: default;
}

.square.promoting {
  z-index: 1;
}

.promotion-grid.light button:hover {
  border: 3px solid rgba(60, 50, 50, .75);
}

.promotion-grid.dark button:hover {
  border: 3px solid rgba(200, 200, 200, .75);
}

.promotion-grid.light,
.promotion-grid.light .promotion-close {
  background-color: rgba(215, 215, 215);
  color: rgba(0, 0, 0, .95)
}

.promotion-grid.dark,
.promotion-grid.dark .promotion-close {
  background-color: rgba(75, 65, 65);
  color: rgba(255, 255, 255, .95);
}

.promotion-grid>button:not(:last-child) {
  height: 7rem;
  width: 7rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid transparent;
  background-color: transparent;
  border-radius: 5px;
}


.promotion-close {
  align-items: center;
  bottom: -3.4rem;
  border: 3px solid transparent;
  border-radius: 4rem;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, .35);
  cursor: pointer;
  display: flex;
  font-size: 1.6rem;
  height: 4rem;
  justify-content: center;
  position: absolute;
  width: 4rem;
}

.square {
  height: 7rem;
  width: 7rem;
  position: relative;

  transition: border .15s;

  display: flex;
  justify-content: center;
  align-items: center;

  /* line-height: 0px; */
}

/* @media (max-width: 800px) {
  .square {
    height: 4rem;
    width: 4rem;
    margin: 4px;
  }
}

@media (max-width: 675px) {
  .square {
    height: 3rem;
    width: 3rem;
    margin: 3px;
  }
} */

.position {
  position: absolute;
  top: 2px;
  right: 3px;
  font-size: 1rem;
  font-weight: normal;
  user-select: none;
  transition: color .5s;
}

.off-board {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.promoting,
.selected,
.possible,
.targeted,
.selectable {
  cursor: pointer;
}

.selectable.dark:hover,
.promotion-grid.white>button:hover:not(:last-child) {
  border: 3px solid rgba(200, 200, 200, .75);
}

.selectable.light:hover,
.promotion-grid.black>button:hover:not(:last-child) {
  border: 3px solid rgba(60, 50, 50, .75);
}

.square.selected {
  background-color: rgba(135, 206, 250, .75);
}

.square.possible {
  background-color: rgba(144, 238, 144, .75);
}

.square.targeted {
  background-color: rgba(240, 128, 128, .75);
}

.square.selected,
.square.possible,
.square.targeted {
  color: rgb(60, 50, 50);
}

.square.selected:hover,
.square.possible:hover,
.square.targeted:hover {
  border: 3px solid rgba(60, 50, 50, .75);
}

.light {
  background-color: rgba(200, 200, 200, .75);
  color: rgb(60, 50, 50);
  border: 3px solid transparent
}

.dark {
  background-color: rgba(60, 50, 50, .75);
  color: rgb(200, 200, 200);
  border: 3px solid transparent
}

.help-window {
  position: absolute;
  top: 2rem;
  right: 2.5rem;
  width: 20%;
}

.help-text {
  font-family: 'Afacad', sans-serif;
  font-size: 1.8rem;
  padding: 1.5rem 3rem;
  user-select: none;
  text-transform: lowercase;
  color: rgba(60, 50, 50, .75);
  border: 1px solid rgba(60, 50, 50, .25);
}

.help-text h2 {
  font-size: 3.2rem;
  margin-bottom: 2.5rem;
  font-weight: normal;
}

.help-text p {
  margin-bottom: 2rem;
}

.help-window.open {
  animation: fadeIn .2s ease-out 1 forwards;
}

.help-window.close {
  animation: fadeOut .2s ease-out 1 forwards;
}

.game-options {
  position: absolute;
  top: 2rem;
  right: 2.5rem;
  display: flex;
  flex-direction: column;
}

.game-options div {
  display: flex;
  align-items: baseline;
  justify-content: right;
}

.game-options button,
#load-buttons button {
  border: none;
  color: rgba(60, 50, 50, .75);
  cursor: pointer;
  font-size: 1.8rem;
  width: 2.8rem;
  background-color: transparent;
}

.game-options button:disabled {
  cursor: default;
}

.game-options button i,
#load-buttons button i {
  transition: all .15s;
}

.game-options button:disabled i,
.game-options button:disabled:hover i,
.game-options button:disabled:active i {
  color: rgba(60, 50, 50, .25);
  text-shadow: none;
  opacity: 1;
  transform: none;
}

.game-options button:hover i,
#load-buttons button:hover i {
  text-shadow: 0 3px 3px rgba(0, 0, 0, 0.25);
  transform: translateY(-1px);
}

.game-options button:active i,
#load-buttons button:active i {
  opacity: .5;
  text-shadow: none;
  transform: none;
}

#message {
  color: rgba(60, 50, 50, .75);
  user-select: none;
  font-size: 2.6rem;
  margin-bottom: 1rem;
}

#status {
  color: rgba(60, 50, 50, .5);
  user-select: none;
  font-size: 2rem;
  margin-left: 1rem;
  font-family: 'Ubuntu Mono', monospace;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadePromo {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-in-nav {
  animation: fadeIn .8s ease-in-out 1 forwards;
}

.fade-in-error {
  animation: fadeIn .2s ease-in-out 1 forwards;
}

.fade-in-slow {
  animation: fadeIn .8s ease-in-out 1 forwards;
}

.fade-in-med {
  animation: fadeIn .6s ease-in-out 1 forwards;
}

.fade-in-fast {
  animation: fadeIn .4s ease-in-out 1 forwards;
}

.fade-in-v-fast {
  animation: fadeIn .2s ease-in-out 1 forwards;
}

/* @media (max-width: 500px) {
  .square {
    height: 2rem;
    width: 2rem;
    margin: 2px;
  }

  .square.possible:hover,
  .square.targeted:hover {
    border: 3px solid transparent;
  }
} */

.home-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16rem;
}

.home-menu h1 {
  color: rgba(60, 50, 50, .75);
  margin-bottom: 2rem;
  font-size: 5rem;
  text-transform: lowercase;
  letter-spacing: 3px;
  user-select: none;
}

.home-menu>button {
  background-color: transparent;
  border: none;
  color: rgba(60, 50, 50, .5);
  cursor: pointer;
  font-family: 'Lora', serif;
  font-size: 3rem;
  letter-spacing: .3rem;
  padding-bottom: 1rem;
  position: relative;
  text-transform: uppercase;
  user-select: none;
}

#new-match {
  margin-bottom: 1rem;
}

.home-upper {
  font-size: 3.6rem;
}

.home-menu>button:hover {
  color: rgba(60, 50, 50, .75);
}

.home-menu>button:active {
  color: rgba(60, 50, 50, .25);
  transition: none;
}

#load-match {
  width: 23.8rem;
}

.home-menu>button,
#new-match::after,
#load-match::after {
  transition: all .15s ease-in-out;
}

#new-match::after,
#load-match::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 2px;
  background-color: rgba(60, 50, 50, .5);
  opacity: 0;
}

#new-match::after {
  transform: translateX(-14rem);
}

#load-match::after {
  transform: translateX(14rem);
}

#new-match:hover::after,
#load-match:hover::after {
  opacity: 1;
  transform: translateX(0);
}

#new-match:active::after,
#load-match:active::after {
  background-color: rgba(60, 50, 50, .1);
  transition: none;
}

#load-buttons {
  position: absolute;
  right: -7rem;
  bottom: 1rem;
  display: flex;
}

#load-form input {
  border: none;
  background-color: rgba(200, 200, 200, .25);
  height: 4.6rem;
  width: 23.8rem;
  font-family: 'Afacad', sans-serif;
  font-size: 2.2rem;
  color: rgba(60, 50, 50, .75);
  letter-spacing: 1px;
  display: flex;
  text-align: center;
  margin-bottom: 1rem;
  padding-bottom: .2rem;
}

#load-form input:focus::placeholder {
  color: transparent;
}

#load-form input::placeholder {
  color: rgba(60, 50, 50, .5);
  text-transform: lowercase;
}

#load-form {
  position: relative;
}

#load-form::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 2px;
  background-color: rgba(60, 50, 50, .1);
}

#home-loading {
  color: rgba(60, 50, 50, .75);
  user-select: none;
  font-size: 2.6rem;
  position: absolute;
  top: 2rem;
  right: 2.5rem;
  animation: fadeIn .2s ease-out 1 forwards;
}

.not-found {
  color: rgba(60, 50, 50, .5);
  margin-bottom: 16rem;
  font-size: 4rem;
  letter-spacing: 3px;
  user-select: none;
}
